/* /////////////////////////////////////
// HOMEPAGE //
///////////////////////////////////// */

.banner{
    display: flex;
    justify-content: center;
    margin:0;
    &__logo{
        width: 30%;
        //temp code, to be replaced and moved to correct scss file
            background-color: $color-accent;
    }
    &__header{
        min-height: 40vh;
        width: 70%;
        //temp code, to be replaced and moved to correct scss file
            background-image: url("https://source.unsplash.com/random/?nature/");
            background-size: cover;
    }
}

.banner__header{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: nth($font-size, 2);
}

.aboutBanner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    max-width: 60%;

    &__title{
        @include mainFont;
        font-size: nth($font-size, 2);
    }

    &__text{
        @include mainFont;
        font-size: nth($font-size, 3);
        line-height: 1.5em;
    }
}

.latestPosts{
    margin-bottom: 2em;
}

.latestPosts__title{
    font-size: nth($font-size, 2);
    @include mainFont;
    text-decoration: underline;
}

.siteTitle{
    @include mainFont;
    color: white;
}
