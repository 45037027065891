html,
body {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  max-width: 100vw;
}

.navbar__ul {
  list-style: none;
  border-top: solid 1px black;
  border-bottom: solid 1px black;
  padding: 1em;
  margin-top: 0;
}
.navbar__ul a {
  text-decoration: none;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
}
.navbar__li {
  display: flex;
  align-items: center;
  border-bottom: dotted 10px black;
  border-top: dotted 10px black;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
  padding: 0.5em 1em;
  font-size: 1.5em;
  transition: ease-in-out 200ms;
}
.navbar__li:hover, .navbar__li:active {
  scale: 120%;
  padding: 0em 2em;
  background-color: rgba(127, 255, 212, 0.246);
  border-radius: 50px;
}

.pageDivider {
  margin: 2em 0;
  min-height: 20vh;
  width: 100%;
  background-image: url("https://source.unsplash.com/random/?nature/");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

.postPreview {
  display: flex;
  margin: auto;
  border-bottom: solid lightgray 1px;
  max-width: 70%;
  max-height: 30vh;
}
.postPreview__img {
  width: 30%;
  border-radius: 20px;
  margin: 2em 0;
}
.postPreview__text {
  width: 70%;
  margin: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.postPreview__title, .postPreview__descript {
  text-align: left;
  margin: 0;
}
.postPreview__title {
  font-size: 3em;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
}
.postPreview__descript {
  font-size: 1.5em;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
}

.postPreviewInverse {
  text-align: right;
}

.latestPostsComponent:last-child {
  border-bottom: none;
}

/* /////////////////////////////////////
// Page Headers //
///////////////////////////////////// */
.headerTopBar {
  max-height: 10vh;
}

.pageHeader {
  background-image: url("https://source.unsplash.com/random/?nature/");
  background-size: cover;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageHeader__title {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
  font-size: 4em;
  background-color: rgba(245, 245, 245, 0.399);
  padding: 1em;
  border-radius: 50px;
}

/* /////////////////////////////////////
// Page Filters //
///////////////////////////////////// */
.optionsBar {
  width: 70%;
  display: flex;
  justify-content: right;
  margin: 2em auto 0 auto;
}

.filters {
  width: 50%;
  height: 2vw;
  display: flex;
  justify-content: space-evenly;
}

.filter, .search {
  border: solid lightgray 1px;
  border-radius: 50px;
  background-color: rgb(249, 249, 249);
}

.filter {
  width: 8em;
}

.search {
  width: 4em;
}

.filterSearchBar {
  border: solid lightgray 1px;
  border-radius: 50px;
  max-width: 50em;
  max-height: 3em;
  padding: 1em;
}

/* /////////////////////////////////////
// Footer //
///////////////////////////////////// */
footer {
  height: 10vh;
  background-color: rgba(127, 255, 212, 0.246);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.footer__loginButton {
  background-color: rgba(127, 255, 212, 0.246);
  font-size: 1em;
  border-radius: 50px;
  border: solid lightgrey 1px;
  padding: 1em;
  text-decoration: none;
  color: black;
}
.footer__loginButton:hover, .footer__loginButton:active {
  cursor: pointer;
}
.footer__buildCredits a {
  color: black;
}

.socialsBar {
  margin: 0;
  position: absolute;
  top: 0;
  right: 50px;
  background-color: rgba(255, 255, 255, 0.41);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: ease-in-out 200ms;
}
.socialsBar:hover, .socialsBar:active {
  scale: 1.5;
  transform: translateY(20px) translateX(-20px);
  border-radius: 50px;
}

.socials {
  max-height: 5vw;
}

@keyframes bounce2 {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-12px);
  }
  40% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}
.social-list {
  padding: 0.5em;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
}
@media (max-width: 737px) {
  .social-list {
    width: 60vw;
  }
}
.social-list__link {
  color: black;
  font-size: 1.5em;
  font-weight: 800;
  padding: 0.5em;
  width: 100%;
  height: 100%;
}
.social-list__item:hover, .social-list__item:active {
  animation: bounce2 2000ms ease infinite;
  cursor: pointer;
}
.social-list__item {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.social-list__item--insta {
  background-image: url("/src/assets/logos/instalogo.webp");
}
.social-list__item--facebook {
  background-image: url("/src/assets/logos/fblogo.webp");
}
.social-list__item--youtube {
  background-image: url("/src/assets/logos/youtubelogo.webp");
}
.social-list__item--kofi {
  background-image: url("/src/assets/logos/kofilogo.webp");
}

html body {
  margin: 0;
  padding: 0;
}

.navbar__ul {
  display: flex;
  justify-content: space-evenly;
}

/* /////////////////////////////////////
// HOMEPAGE //
///////////////////////////////////// */
.banner {
  display: flex;
  justify-content: center;
  margin: 0;
}
.banner__logo {
  width: 30%;
  background-color: rgba(127, 255, 212, 0.246);
}
.banner__header {
  min-height: 40vh;
  width: 70%;
  background-image: url("https://source.unsplash.com/random/?nature/");
  background-size: cover;
}

.banner__header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.aboutBanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 60%;
}
.aboutBanner__title {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
  font-size: 3em;
}
.aboutBanner__text {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
  font-size: 1.5em;
  line-height: 1.5em;
}

.latestPosts {
  margin-bottom: 2em;
}

.latestPosts__title {
  font-size: 3em;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
  text-decoration: underline;
}

.siteTitle {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: black;
  color: white;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
}
.homepage__logo {
  max-height: 70vh;
  max-width: 100%;
}

