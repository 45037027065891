
html body{
    margin: 0;
    padding: 0;
}

.navbar{
    &__ul{
        display: flex;
        justify-content: space-evenly;
    }
}

