.navbar{
    &__ul{
        list-style: none;
        border-top: solid 1px black;
        border-bottom: solid 1px black;
        padding: 1em;
        margin-top: 0;
    }
    &__ul a{
        text-decoration: none;
        @include mainFont;
    }
    &__li{
        display: flex;
        align-items: center;

        border-bottom: dotted 10px black;
        border-top: dotted 10px black;

        @include mainFont;
        padding: 0.5em 1em;
        font-size: nth($font-size, 3);

        transition: ease-in-out 200ms;
    }
    &__li:hover, &__li:active{
        scale: 120%;
        padding: 0em 2em;
        background-color: $color-accent;
        border-radius: 50px;
    }

}

.pageDivider{
    margin: 2em 0;
    min-height: 20vh;
    width: 100%;
    background-image: url("https://source.unsplash.com/random/?nature/");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

.postPreview{
    display: flex;
    margin: auto;
    border-bottom: solid lightgray 1px;
    max-width: 70%;
    max-height: 30vh;
    &__img{
        width: 30%;
        border-radius: 20px;
        margin: 2em 0;
    }
    &__text{
        width: 70%;
        margin: 2em;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    &__title, &__descript{
        text-align: left;
        margin: 0;

    }
    &__title{
        font-size: nth($font-size, 2);
        @include mainFont;
    }
    &__descript{
        font-size: nth($font-size, 3);
        @include mainFont;
    }
}

.postPreviewInverse{
    text-align: right;
}

.latestPostsComponent:last-child {
    border-bottom: none;
}



/* /////////////////////////////////////
// Page Headers //
///////////////////////////////////// */

.headerTopBar{
    max-height: 10vh;
}

.pageHeader{
    background-image: url("https://source.unsplash.com/random/?nature/");
    background-size: cover;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.pageHeader__title{
    @include mainFont;
    font-size: nth($font-size, 1);
    background-color: rgba(245, 245, 245, 0.399);
    padding: 1em;
    border-radius: 50px;
   
}

/* /////////////////////////////////////
// Page Filters //
///////////////////////////////////// */

.optionsBar{
    width: 70%;
    display: flex;
    justify-content: right;
    margin: 2em auto 0 auto;
}

.filters{
    width: 50%;
    height: 2vw;
    display: flex;
    justify-content: space-evenly;
}

.filter, .search{
    border: solid lightgray 1px;
    border-radius: 50px;
    background-color: rgb(249, 249, 249);
}

.filter{
    width: 8em;
}

.search{
    width: 4em;
}
.filterSearchBar{
    border: solid lightgray 1px;
    border-radius: 50px;
    max-width: 50em;
    max-height: 3em;
    padding: 1em;
}


/* /////////////////////////////////////
// Footer //
///////////////////////////////////// */

footer{
    height: 10vh;
    background-color: $color-accent;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer{
    &__loginButton{
        background-color: $color-accent;
        font-size: nth($font-size, 4);
        border-radius: 50px;
        border: solid lightgrey 1px;
        padding: 1em;
        text-decoration: none;
        color: $color-primary;
    }
    &__loginButton:hover, &__loginButton:active{
        cursor: pointer;
    }

    &__buildCredits a{
        color: black;
    }
}
