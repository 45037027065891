.socialsBar {
  margin: 0;
  position: absolute;
  top: 0;
  right: 50px;
  background-color: rgba(255, 255, 255, 0.41);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  transition: ease-in-out 200ms;
  &:hover,
  &:active {
    scale: 1.5;
    transform: translateY(20px) translateX(-20px);
    border-radius: 50px;
  }
}

.socials {
  max-height: 5vw;
}

// .bounce2:hover,
// .bounce2:focus {
//   background-color: $color-accent;
// }

@keyframes bounce2 {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-12px);
  }
  40% {
    transform: rotate(-30deg);
  }
  50% {
    transform: rotate(30deg);
  }
  60% {
    transform: rotate(0deg);
  }
  70% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.social-list {
  padding: 0.5em;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  @media (max-width: 737px) {
    width: 60vw;
  }
  &__link {
    color: $color-primary;
    font-size: 1.5em;
    font-weight: 800;
    padding: 0.5em;
    width: 100%;
    height: 100%;
  }
  &__item:hover,
  &__item:active {
    animation: bounce2 2000ms ease infinite;
    cursor: pointer;
  }
  &__item {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &--insta {
      background-image: url('/src/assets/logos/instalogo.webp');
    }
    &--facebook {
      background-image: url('/src/assets/logos/fblogo.webp');
    }
    &--youtube {
      background-image: url('/src/assets/logos/youtubelogo.webp');
    }
    &--kofi {
      background-image: url('/src/assets/logos/kofilogo.webp');
    }
  }
}
